import React, {useState, useEffect } from "react"
import styled from "styled-components"

const IndexPage = () => {

	useEffect(() => {
		if (typeof window !== "undefined") {
			console.log("Redirecting to checkout page")
			window.location.replace('https://deliver.unqueue.app')
		}
	}, [])

return (
  <Container>
	  <div>redirecting</div>
  </Container>
)
}

export default IndexPage
const Container = styled.div`
	width: 100%;
`;